// config.js
const configs = {
    development: {
        API_URL: "http://dictionary.pragmadevs.com:5000/"
    },
    production: {
        API_URL: "https://apidict.pragmadevs.com/"
    }
};

const currentEnv = process.env.NODE_ENV || "development";

module.exports = configs[currentEnv];
